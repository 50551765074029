/* You can add global styles to this file, and also import other style files */
//bootstrap grid
@import "_variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

[data-lastpass-icon-root],
[data-lastpass-root] {
  display: none !important;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}
.current {
  background: $indi-primary !important;
}
.primary-color {
  color: $indi-primary;
}

.green {
  color: $indi-secondary;
}

.red {
  color: $indi-color-red;
}

.white {
  color: $indi-color-white;
}
.width-3 {
  width: 3%;
}
.width-5 {
  width: 5%;
}
.width-7 {
  width: 7.5%;
}
.width-10 {
  width: 10%;
}
.width-15 {
  width: 15%;
}
.width-20 {
  width: 20%;
}
.width-30 {
  width: 25%;
}
.width-50 {
  width: 50%;
}

//TABLE styling

fixed-table-layout {
  table-layout: fixed;
  word-wrap: break-word;
}

.sort-icon {
  display: none;
}

.sort {
  cursor: pointer;
}
.sort:hover {
  color: $indi-primary-hover;
  background-color: $indi-color-grey-lighter;
}

.highlight {
  display: inline-block;
  color: $indi-primary;
}
.error-text {
  color: $indi-color-red;
}

.searchbar {
  height: 60px;
  background-color: $indi-color-grey-light;
  padding: 10px;
}

.search_input {
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  caret-color: transparent;
  line-height: 40px;
  color: $indi-color-grey-dark;
}

.search_input {
  padding: 0 10px;
  width: 450px;
  caret-color: $indi-primary;
}

.search_icon {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.feedback_box {
  border: 1px solid $indi-color-black;
  background-color: $indi-color-white;
  position: absolute;
  top: 25vh;
  left: 20vw;
  right: 20vw;
}

.no-arrows-in-number-input {
  appearance: textfield;
  -moz-appearance: textfield;
}

.no-arrows-in-number-input::-webkit-outer-spin-button,
.no-arrows-in-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.offset-rows-color td {
  background-color: $gray-250;
}